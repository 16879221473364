import React from "react"
import { Text, Div, Modal, Image } from "atomize"
import CardHeader from "../CardHeader"

const PdpModal = ({ isOpen, onClose, variantImage }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        align="center"
        rounded="lg"
        p="0"
        shadow="4"
      >
        <Div>
          <CardHeader heading="preview" close={true} closeModal={onClose} />
          <Div bg="yellow" p="1.25rem" rounded={{ b: "lg" }}>
            <Div
              bg="white"
              rounded="lg"
              border="3px solid"
              borderColor="blue"
              p="1.875rem"
            >
              <Image src={variantImage} maxH="27.25rem" maxW="25rem" />
            </Div>
          </Div>
        </Div>
      </Modal>
    </>
  )
}

export default PdpModal
