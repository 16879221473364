import {get} from "lodash"
import ls from "local-storage"
import React, {useContext, useState} from "react"
import {Div, Icon, Text} from "atomize"
import {useMutation, useQuery} from "@apollo/client"
import {isBefore} from 'date-fns'

import BlueButton from "../common/molecules/BlueButton"
import {
  ADD_ATTRIBUTES,
  APPLY_DISCOUNT,
  CHECKOUT_BY_ID,
  CHECKOUT_CREATE,
  CHECKOUT_LINE_ITEMS_ADD,
  FETCH_CUSTOMER,
  GET_RECHARGE_CHECKOUT,
  REMOVE_DISCOUNT,
  TOGGLE_CART,
  TOGGLE_RECHARGE_CART,
  UPDATE_RECHARGE_CHECKOUT
} from "../../graphql/queries"
import {addItemToCheckout, addItemToRechargeCheckout, saveCheckoutInLocalStorage,} from "../../services/cartService"
import {trackAddToBag} from '../../helpers/freshpaint';
import {AppContext} from "../../context";

const ProductAddToBag = ({product, count, changeCounterValue, variant, productType, frequency,}) => {
  const [state, dispatch] = useContext(AppContext);
  const [addAttributes] = useMutation(ADD_ATTRIBUTES)
  const [applyDiscount] = useMutation(APPLY_DISCOUNT, {
    optimisticResponse: response => console.log('optimisticResponse add to bag', response),
    onError: (error => console.error(error))
  })
  const [removeDiscount] = useMutation(REMOVE_DISCOUNT, {
    optimisticResponse: response => console.log(response),
    onError: (error => console.error(error))
  })
  const {tracking} = state;
  const [] = useState(false)
  const [updatingRechargeCheckout, setUpdatingRechargeCheckout] = useState(
    false
  )

  const {data: fetchCustomerData, loading: fetchCustomerLoading} = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: {accessToken: ls("accessToken")},
    }
  )

  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
    },
  })
  const [toggleRechargeCart] = useMutation(TOGGLE_RECHARGE_CART)

  const {data: checkoutData} = useQuery(CHECKOUT_BY_ID, {
    skip: !ls("lastCheckoutID"),
    variables: {id: ls("lastCheckoutID")},
    optimisticResponse: response => console.log('optimisticResponse', response),

  })

  const {data: rechargeCheckoutData, refetch: refetchRechargeCart} = useQuery(
    GET_RECHARGE_CHECKOUT,
    {
      skip: !ls("lastRechargeCheckoutID"),
    }
  )

  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  const [checkoutCreate, {loading: creatingCheckout}] = useMutation(
    CHECKOUT_CREATE,
    {
      onError: (error => console.error(error)),
      onCompleted: result => {
        //window.history.pushState("header", "Not Pot", "/#cart")
        toggleCart({variables: {cartDrawerVisible: true}})
        const {checkoutCreate = {}} = result;
        const {checkout = {}} = checkoutCreate;
        if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode) {
          const input = {
            customAttributes: [{key: 'referCode', value: tracking.referCode}, {
              key: 'customerId',
              value: tracking.customerId
            }]
          };
          const checkoutId = checkout.id.toString();
          addAttributes({
            variables: {
              checkoutId,
              input
            }
          }).then(result => {
            const discountCode = tracking.discountCode;
            applyDiscount({
              variables: {
                checkoutId,
                discountCode
              }
            }).then(result => console.info(result))

          }).catch(e => console.error(e))

        } else {
          const checkoutId = checkout.id.toString();
          removeDiscount({variables: {checkoutId}});
        }
        saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)

      },

    }
  )

  const [checkoutLineItemsAdd, {loading: addingCheckoutItem}] = useMutation(
    CHECKOUT_LINE_ITEMS_ADD,
    {
      onCompleted: result => {
        // window.history.pushState("header", "Not Pot", "/#Cart")
        toggleCart({variables: {cartDrawerVisible: true}})
      },
    }
  )

  const customer = get(fetchCustomerData, "customer")

  /**
   * on click on add to cart button
   */
  const onClickAddToCartButton = () => {
    trackAddToBag(product, variant, count);
    if (productType === "one-time") {
      if (
        get(
          rechargeCheckoutData,
          "rechargeCheckout.completed_at",
          undefined
        ) === null
      ) {
        addItemToRechargeCheckout({
          variant,
          email: get(customer, "email", undefined),
          quantity: count,
          frequency: undefined,
          checkout: checkoutData?.node,
          rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
          refetchCart: refetchRechargeCart,
          updateRechargeCheckout,
          toggleRechargeCart: toggleCart,
          setUpdatingRechargeCheckout,
        })
      } else {
        addItemToCheckout({
          checkout: checkoutData?.node,
          checkoutCreate,
          checkoutLineItemsAdd,
          variant,
          quantity: count,
          email: get(customer, "email", undefined),
        })
      }
    }

    if (productType === "subscribe") {
      addItemToRechargeCheckout({
        variant,
        email: get(customer, "email", undefined),
        quantity: count,
        frequency,
        checkout: checkoutData?.node,
        rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
        updateRechargeCheckout,
        toggleRechargeCart: toggleCart,
        setUpdatingRechargeCheckout,
      })
    }
  }

  return (
    <>
      <Div d="flex" justify="space-between">
        <Div
          d="flex"
          align="center"
          h="3.125rem"
          align="center"
          minW="4.375rem"
          justify="space-around"
          p="6px 8px 8px"
          bg="white"
          shadow="2"
          rounded="lg"
          m={{r: "10px"}}
        >
          <Div
            d="flex"
            align="center"
            textWeight="bold"
            onClick={() => changeCounterValue("minus")}
            cursor="pointer"
            p="0 8px"
            h="100%"
          >
            -
          </Div>
          <Text textWeight="bold" m="0 8px">
            {count}
          </Text>
          <Div
            d="flex"
            align="center"
            textWeight="bold"
            onClick={() => changeCounterValue("plus")}
            cursor="pointer"
            p="0 8px"
            h="100%"
          >
            +
          </Div>
        </Div>
        <BlueButton
          w="100%"
          text="Add to bag"
          rounded="lg"
          h="3.125rem"
          onClick={onClickAddToCartButton}
          disabled={
            creatingCheckout || addingCheckoutItem || updatingRechargeCheckout
          }
          prefix={
            <Icon
              name="Loading"
              pos="absolute"
              top="50%"
              left="8.5%"
              transform="translateY(-50%)"
              size="25px"
              color="white"
              style={{
                zIndex: 1,
                opacity:
                  creatingCheckout ||
                  addingCheckoutItem ||
                  updatingRechargeCheckout
                    ? 1
                    : 0,
              }}
              m={{r: "0.5rem"}}
            />
          }
        />
      </Div>
    </>
  )
}

export default ProductAddToBag
