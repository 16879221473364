import React, { useState } from "react"
import { get } from "lodash"
import { navigate } from "gatsby"
import { Div, Text, Image, Button } from "atomize"
import slugify from "slugify"

const ProductVariants = ({
  slug,
  variants,
  product,
  selectedVariant,
  setSelectedSlide,
  setSelectedVariant,
}) => {
  return (
    <Div d="flex" flexWrap="wrap">
      {variants.map(variant => {
        const computedSlug = slugify(`${variant.variantTitle}`, {
          lower: true,
        })

        return (
          <Button
            key={variant._id}
            bg="pink"
            textColor="yellowPink"
            shadow={selectedVariant._id === variant._id ? "2" : ""}
            rounded="20px"
            transition=".5s all ease-in-out"
            border="1px solid"
            borderColor={
              variant._id === selectedVariant._id ? "transparent" : "yellowPink"
            }
            p="10px 1.25rem"
            d="flex"
            onClick={() => {
              console.log({ slug })

              navigate(`/products/${slug}/${computedSlug}`)
              setSelectedVariant(variant)
              setSelectedSlide(0)
            }}
            align="center"
            m={{ r: "10px", b: "10px" }}
          >
            {get(variant, "icon.asset.url") && (
              <Image
                src={get(variant, "icon.asset.url")}
                h="1rem"
                w="1rem"
                m={{ r: "5px" }}
              />
            )}
            <Text
              textTransform="uppercase"
              textWeight="bold"
              textColor="blue"
              style={{ lineHeight: "14px" }}
              textWeight="bold"
              fontFamily="secondary"
              className={
                variant._id === selectedVariant._id ? "active-variant" : ""
              }
            >
              {variant.variantTitle}
            </Text>
          </Button>
        )
      })}
    </Div>
  )
}

export default ProductVariants
