import React from "react"
import { get } from "lodash"
import slugify from "slugify"
import queryString from "query-string"

// modules
import ModuleProductInfo from "../modules/ModuleProductInfo"
import ModuleProductReviews from "../modules/ModuleProductReviews"
import ModuleContentWithFeatures from "../modules/ModuleContentWithFeatures"
import ModuleContentWithProductCard from "../modules/ModuleContentWithProductCard"

/**
 * // returns default variant to be used
 *
 * @param {*} param0
 */
export const getDefaultVariant = ({ variantSlug, data }) => {
  if (variantSlug) {
    const variant = data.product.variants.find(variant => {
      const computedSlug = slugify(`${variant.variantTitle}`, {
        lower: true,
      })

      return computedSlug === variantSlug
    })

    if (variant) return variant

    return data?.product?.defaultVariant
  }

  return data?.product?.defaultVariant || get(data, "product.variants.0")
}

/**
 * render product modules
 *
 * @param {*} param0
 */
export const getProductModules = ({
                                    product,
                                    junipProduct,
                                    checkoutData,
                                    checkoutCreate,
                                  }) => {
  const content = (product._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "features":
          el = <ModuleContentWithFeatures key={c._key} {...c} />
          break
        case "productInfo":
          el = <ModuleProductInfo key={c._key} {...c} product={product} />
          break
        case "reviews":
          el = junipProduct && (
            <ModuleProductReviews
              key={c._key}
              {...c}
              product={product}
              junipProduct={junipProduct}
            />
          )
          break
        case "contentWithProductCards":
          el = (
            <ModuleContentWithProductCard
              key={c._key}
              {...c}
              checkoutData={checkoutData}
              checkoutCreate={checkoutCreate}
            />
          )
          break
        default:
          el = null
      }
      return el
    })

  return content
}
