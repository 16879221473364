import {Div} from "atomize"
import {graphql} from "gatsby"
import React, {useContext, useEffect, useState} from "react"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"
import {trackProductPage} from '../helpers/freshpaint';
import {getDefaultVariant, getProductModules,} from "../services/productService"
import {SEO} from "../components/common/SEO"
import Layout from "../components/common/Layout"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {
  ADD_ATTRIBUTES,
  APPLY_DISCOUNT,
  CHECKOUT_BY_ID,
  CHECKOUT_CREATE,
  REMOVE_DISCOUNT,
  TOGGLE_CART,
} from "../graphql/queries"

// modules
import ModulePdpHero from "../modules/ModulePdpHero"
import {AppContext} from "../context";
import {isBefore} from "date-fns";

const Product = props => {
  const [state, dispatch] = useContext(AppContext);
  const [addAttributes] = useMutation(ADD_ATTRIBUTES)
  const [removeDiscount] = useMutation(REMOVE_DISCOUNT, {
    optimisticResponse: response => console.log(response),
    onError: (error => console.error(error))
  })
  const [applyDiscount] = useMutation(APPLY_DISCOUNT, {
    optimisticResponse: response => console.log(response),
    onError: (error => console.error(error))
  })
  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      onCompleted: result => {
        const {tracking} = state;
        const {id = null, orderStatusUrl= null} = result && result?.node || {};
        if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode && !orderStatusUrl) {


          if(id){
            const input = {
              customAttributes: [{key: 'referCode', value: tracking.referCode}, {
                key: 'customerId',
                value: tracking.customerId
              }]
            };
            const checkoutId = id.toString();
            addAttributes({
              variables: {
                checkoutId,
                input
              }
            }).then(result => {
              const discountCode = tracking.discountCode;
              applyDiscount({
                variables: {
                  checkoutId,
                  discountCode
                }
              }).then(result => console.info(result))

            }).catch(e => console.error(e))
          }

        } else if (orderStatusUrl) {
          ls.remove("lastCheckoutID");
        } else {
          if(id){
            const checkoutId = id.toString();
            removeDiscount({variables: {checkoutId}})
          }

        }
      }
    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({
        variables: {cartDrawerVisible: true},
      })
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      console.log(ls("lastCheckoutID"), 'checkout-create', result)
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
    onSuccess: success => {
      console.log('created checkout', success)
    }
  })
  //*************************** */
  const {data, errors} = props

  const [selectedVariant, setSelectedVariant] = useState(
    getDefaultVariant({
      variantSlug: props.pathContext.variantSlug,
      data,
    })
  )

  useEffect(() => {
    trackProductPage(product);
  }, [])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }

  const site = (data || {}).site
  const {junipProduct = {}, product = {}} = data
  const {og = {}, id = 0} = product
  const {seo = {}} = selectedVariant;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const title = selectedVariant?.title
  const modules = getProductModules({
    product,
    junipProduct,
    checkoutData,
    checkoutCreate,
  })
  const seoTitle = seo?.seo_title || title;
  const seoDescription = seo?.meta_description || product?.description;
  const seoKeywords = seo?.focus_synonyms || [];
  const seoFocus = seo?.focus_keyword || "";
  const metaKeywords = [seoFocus].concat(seoKeywords);
  const media = selectedVariant?.carousel?.assets[0]?.image?.asset?.url;
  const socialTitle = og?.title || seoTitle;
  const socialDesc = og?.description || seoDescription;
  const productMetaData = {
    price: selectedVariant?.price,
    quantity: 1,
    productId: id,
    productGroupId: selectedVariant?._id
  };
  return (
    <Layout site={site}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={metaKeywords}
        site={site}
        media={media}
        url={`https://notpot.com/${props.path}`}
        socialTitle={socialTitle}
        socialDesc={socialDesc}
        headComponents={site.headComponents}
        productInfo={productMetaData}
      />
      <Div>
        <Div
          p={{t: {xs: "1.875rem", lg: "2.5rem"}}}
          className="pdf-hero-container"
        > <ModulePdpHero
          product={product}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          junipProduct={junipProduct}
          key={props.location.search}
          slug={props.pathContext.slug}
        />
        </Div>
        {modules}
      </Div>
    </Layout>
  )
}

export default Product

export const query = graphql`
  query ProductTemplateQuery($id: String!, $slug: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      sanityId
      title
      mobileTitle
      desktopTitle
      supplementFactsFineprint
      latestLabReportsFineprint
      seo {
        seo_title
        meta_description
        focus_keyword
        focus_synonyms
      }
      og{
        title
        description
      }
      variants {
        _id
        title
        price
        discountedPrice
        sku
        inventory_quantity
        variantTitle
        productDescription: _rawProductDescription
        hasSubscription
        icon {
          asset {
            url
          }
        }
        supplementFacts {
          asset {
            url
          }
        }
        latestCoas {
          asset {
            url
          }
        }
        carousel {
          title
          icon {
            asset {
              url
            }
          }
          assets {
            video {
              asset {
                url
              }
            }
            image {
              asset {
                url
              }
            }
            caption
            alt
          }
        }
        seo {
          seo_title
          meta_description
          focus_keyword
          focus_synonyms
        }
      }
      defaultVariant {
        _id
        title
        price
        discountedPrice
        sku
        inventory_quantity
        variantTitle
        productDescription: _rawProductDescription
        hasSubscription
        icon {
          asset {
            url
          }
        }
        supplementFacts {
          asset {
            url
          }
        }
        latestCoas {
          asset {
            url
          }
        }
        carousel {
          title
          icon {
            asset {
              url
            }
          }
          assets {
            video {
              asset {
                url
              }
            }
            image {
              asset {
                url
              }
            }
            caption
            alt
          }
        }
        seo {
          seo_title
          meta_description
          focus_keyword
          focus_synonyms
        }
        og{
          title
          description
        }
      }
      _rawContent(resolveReferences: { maxDepth: 20 })
    }

    junipProduct(remote_handle: { eq: $slug }) {
      id
      rating_average
      rating_count
      remote_id
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
