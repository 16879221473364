import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Div, Text, Label, Input, Image, Icon, Collapse } from "atomize"
import { useLocation } from "@reach/router"
import axios from "axios"
import * as yup from "yup"
import { Formik } from "formik"
import { Link } from "gatsby"

import ProductVariants from "./ProductVariants"
import ProductAddToBag from "./ProductAddToBag"
import ProductHeroRating from "./ProductHeroRating"
import PortableText from "../../components/common/PortableText"
import BlueButton from "../common/molecules/BlueButton"

const deliveryOptions = [15, 30, 45, 60]

const ProductDescription = ({
  product,
  selectedVariant,
  setSelectedVariant,
  setSelectedSlide,
  junipProduct,
  slug,
}) => {
  const location = useLocation()
  const [count, setCount] = useState(1)
  const [dropdown, showDropdown] = useState("")
  const [delivery, setSelectedDelivery] = useState(deliveryOptions[1])
  const [checkbox, setCheckbox] = useState("one-time")

  let formikProps

  const initialValues = {
    email: "",
  }
  const addprice = value => {
    const discountPrice = setSelectedVariant.price - value
  }
  const onSubmit = values => {
    const { setSubmitting, setStatus, resetForm } = formikProps
    const klaviyoPromise = axios.post(`/api/instock`, {
      email: values.email,
      variant: selectedVariant._id,
      product: product._id,
    })

    Promise.all([klaviyoPromise])
      .then(responses => {
        setSubmitting(false)
        resetForm(initialValues)
        setStatus({
          successMessage:
            "Thanks! Please be on the lookout for emails and texts from the Dealer ;)",
          errorMessage: "",
        })
      })
      .catch(error => {
        setSubmitting(false)
        setStatus({
          errorMessage: "Something went wrong. Please try again later.",
          successMessage: "",
        })
      })
  }

  const validator = yup.object().shape({
    email: yup
      .string()
      .required("Please use a valid email address.")
      .min(1)
      .email("Please use a valid email address."),
  })

  const changeCounterValue = value => {
    if (value === "plus") {
      setCount(prevCount => prevCount + 1)
    } else {
      if (count === 1) {
        return null
      }
      setCount(prevCount => prevCount - 1)
    }
  }

  useEffect(() => {
    // if (
    //   performance.navigation.type == 2 &&
    //   performance.navigation.TYPE_RELOAD == 1
    // ) {
    //   //  window.history.pushState("home", "Not Pot", "/");
    // //  window.location.reload()
    // }
    showDropdown(false)
  }, [checkbox])
  return (
    <>
      <Div maxW={{ xs: "100%", lg: "21rem" }} className="pdp">
        <Div border={{ b: "1px solid" }} borderColor="borderColor">
          <Text
            textWeight="bold"
            textTransform="uppercase"
            textSize="display1"
            style={{ lineHeight: "32px" }}
            fontFamily="secondary"
          >
            {product.desktopTitle || product.title}
          </Text>
          {junipProduct?.rating_count >= 1 && (
            <Div m={{ t: "1rem" }}>
              <ProductHeroRating junipProduct={junipProduct} />
            </Div>
          )}
          {product.variants.length > 1 && (
            <Div m={{ t: "1.875rem" }} d="flex">
              <ProductVariants
                slug={slug}
                product={product}
                variants={product.variants}
                selectedVariant={selectedVariant}
                setSelectedSlide={setSelectedSlide}
                setSelectedVariant={setSelectedVariant}
              />
            </Div>
          )}
          {selectedVariant.productDescription && (
            <Div m={{ t: "1.875rem", b: "2.5rem" }}>
              <PortableText blocks={selectedVariant.productDescription} />
            </Div>
          )}
        </Div>

        {/* //Price */}
        <Div m={{ t: "2.5rem" }}>
          <Div d="flex" flexDir="column">
            <Div d="flex">
              {selectedVariant.hasSubscription && checkbox === "subscribe" && (
                <Text
                  textSize="button"
                  textWeight="bold"
                  fontFamily="secondary"
                  m={{ r: "10px" }}
                  textDecor="line-through"
                  opacity="0.3"
                >
                  ${selectedVariant.price}
                </Text>
              )}
              <Text textSize="button" textWeight="bold" fontFamily="secondary">
                $
                {checkbox === "one-time"
                  ? selectedVariant.price
                  : selectedVariant.discountedPrice}
              </Text>
            </Div>
            {checkbox === "one-time" && selectedVariant.inventory_quantity > 0 && (
              <Div
                m={{ t: "5px" }}
                fontFamily="primary"
                d="flex"
                align="center"
              >
                <Text textSize="body" textWeight="500">
                  Or 4 interest-free payments of{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ${Math.round(selectedVariant.price / 4)}
                  </span>{" "}
                  with
                </Text>
                <img src="/Vector.svg" style={{ marginLeft: 4 }} />
              </Div>
            )}
          </Div>

          <Div m={{ t: "1.875rem" }}>
            {selectedVariant.hasSubscription && (
              <>
                {selectedVariant.inventory_quantity > 0 && (
                  <>
                    <Label
                      textSize="body"
                      textWeight="bold"
                      style={{ lineHeight: "16px" }}
                      align="center"
                      m={{ b: "10px" }}
                      onClick={() => setCheckbox("one-time")}
                    >
                      <input
                        type="radio"
                        className="apply-radio"
                        checked={checkbox === "one-time" ? "checked" : ""}
                        name="count"
                      />
                      Just one time
                    </Label>

                    <Label
                      bgColor="white"
                      onClick={() => setCheckbox("subscribe")}
                    >
                      <input
                        type="radio"
                        className="apply-radio"
                        checked={checkbox === "subscribe" ? "checked" : ""}
                        name="subscribe"
                      />
                      <Div>
                        <Text
                          textColor="blacklight"
                          textWeight="bold"
                          textSize="body"
                          style={{ lineHeight: "16px" }}
                        >
                          Subscribe & save
                        </Text>
                        {/* <Text textSize="paragraph1" m={{ t: "5px" }}>
                          Skip, adjust, or cancel anytime.
                        </Text> */}
                        <Div d="flex" align="center" m={{ t: "10px" }}>
                          <img src="/pdp-tick.svg" />
                          <Text textSize="paragraph1" m={{ l: "5px" }}>
                            Save $4.20 per item
                          </Text>
                        </Div>

                        <Div d="flex" align="center" m={{ t: "5px" }}>
                          <img src="/pdp-tick.svg" />
                          <Text textSize="paragraph1" m={{ l: "5px" }}>
                            Free shipping for subscribers
                          </Text>
                        </Div>
                        <Div d="flex" align="center" m={{ t: "5px" }}>
                          <img src="/pdp-tick.svg" />
                          <Text textSize="paragraph1" m={{ l: "5px" }}>
                            Early access to new products
                          </Text>
                        </Div>

                        {checkbox === "subscribe" && (
                          <Div>
                            <Div
                              maxW="12.5rem"
                              pos="relative"
                              //bg="transparent"
                              focusBg="white"
                              focusBorderColor="blue"
                              p="10px 15px"
                              className={
                                dropdown
                                  ? "rounded-dropdown"
                                  : "rounded-dropdown-closed"
                              }
                              m={{ t: "10px" }}
                              border="1px solid"
                              borderColor="blue"
                              textColor="blue"
                              onClick={() => showDropdown(!dropdown)}
                            >
                              {" "}
                              <Image
                                src="/reload.svg"
                                h=".5rem"
                                w=".5rem"
                                m={{ r: "10px" }}
                              />
                              Every {delivery} days
                              <Image
                                pos="absolute"
                                src="/toggle-arrow.svg"
                                h=".5rem"
                                w=".5rem"
                                right="15px"
                                top="50%"
                                transform={`rotateZ(${
                                  dropdown ? "-180" : "0"
                                }deg)
                        translateY(${dropdown ? "50%" : "-50% "}) `}
                                transition
                                //{`translateY(-50%)`}
                              />
                            </Div>
                            <Collapse
                              isOpen={dropdown}
                              onClick={() => showDropdown(!dropdown)}
                              m={{ t: "-10px" }}
                            >
                              <Div
                                bg="white"
                                //pos="absolute"
                                w="100%"
                                top="-11px"
                                p="10px 13px"
                                maxH="10rem"
                                overflow="auto"
                                border={{
                                  t: "0",
                                  x: "1px solid",
                                  b: "1px solid",
                                }}
                                borderColor="blue"
                                rounded={{ b: "20px" }}
                              >
                                <Div h="1px" bg="blue" m={{ b: "5px" }}></Div>
                                {deliveryOptions.map((name, index) => (
                                  <Text
                                    bg="transparent"
                                    d="block"
                                    p={{ y: "0.25rem" }}
                                    cursor="pointer"
                                    onClick={() => {
                                      setSelectedDelivery(name)
                                      showDropdown(false)
                                      addprice("4.20")
                                    }}
                                  >
                                    Every {name} days
                                  </Text>
                                ))}
                              </Div>
                            </Collapse>
                          </Div>
                        )}
                      </Div>
                    </Label>
                  </>
                )}
              </>
            )}

            {selectedVariant.inventory_quantity > 0 && (
              <>
                <Div m={{ t: "2.5rem" }}>
                  <ProductAddToBag
                    product={product}
                    count={count}
                    changeCounterValue={value => changeCounterValue(value)}
                    variant={selectedVariant}
                    productType={checkbox}
                    frequency={delivery}
                  />
                </Div>
              </>
            )}

            {selectedVariant.inventory_quantity <= 0 && (
              <Div m={{ t: "2.5rem" }}>
                <Text m={{ b: "10px" }} textSize="paragraph1" p={{ b: "1rem" }}>
                  <span style={{ color: "#E74F3C", fontWeight: "bold" }}>
                    Out of stock
                  </span>{" "}
                  - notify me when it’s available
                </Text>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validator}
                >
                  {props => {
                    const {
                      values,
                      status,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      submitForm,
                    } = props
                    formikProps = props
                    {
                      /* console.log(status) */
                    }
                    return (
                      <form onSubmit={handleSubmit}>
                        {status && status.successMessage && (
                          <Text
                            textColor="success700"
                            m={{ b: "1rem" }}
                            opacity={status.successMessage ? "1" : "0"}
                            transition
                          >
                            {status.successMessage}
                          </Text>
                        )}
                        {status && status.errorMessage && (
                          <Text
                            textColor="danger700"
                            m={{ b: "1rem" }}
                            opacity={status.errorMessage ? "1" : "0"}
                            transition
                          >
                            {status.errorMessage}
                          </Text>
                        )}
                        <Div
                          d={
                            (status && status.successMessage) ||
                            (status && status.errorMessage)
                              ? "none"
                              : "flex"
                          }
                        >
                          <Div flexGrow="2">
                            <Input
                              type="email"
                              name="email"
                              placeholder="Email"
                              placeholderTextColor="navy"
                              shadow="2"
                              textColor="blue"
                              h="3.125rem"
                              border="1px solid"
                              borderColor={
                                touched.email
                                  ? values.email
                                    ? errors.email
                                      ? "red"
                                      : "oilGreen"
                                    : errors.email
                                    ? "red"
                                    : "transparent"
                                  : "transparent"
                              }
                              m={{ r: "10px" }}
                              p="0 2.125rem"
                              prefix={
                                <Image
                                  src="/email.svg"
                                  w="14px"
                                  h="10px"
                                  pos="absolute"
                                  top="50%"
                                  left="0.75rem"
                                  transform="translateY(-50%)"
                                />
                              }
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Text
                              textColor="error"
                              textSize="paragraph"
                              m={{ t: ".5rem" }}
                            >
                              {touched.email && _.capitalize(errors.email)}
                            </Text>
                          </Div>
                          <BlueButton
                            text={
                              isSubmitting ? (
                                <Icon
                                  name="Loading"
                                  color="white"
                                  size="16px"
                                />
                              ) : (
                                "Sign Up"
                              )
                            }
                            type="submit"
                            padding="0.5rem 1.4rem"
                            textSize="header1"
                            h="3.125rem"
                          />
                        </Div>
                      </form>
                    )
                  }}
                </Formik>
              </Div>
            )}
            <Div
              d="flex"
              m={{
                t:
                  selectedVariant.inventory_quantity > 0
                    ? "1.25rem"
                    : "0.75rem",
              }}
            >
              <Image src="/dove.svg" h="30px" w="30px" p={{ r: "10px" }} />

              <Text textSize="paragraph">
                We use profits to help pay for someone’s bail every month
                through our{" "}
                <Link to="/mission">
                  <Text
                    tag="span"
                    textColor="blue"
                    hoverTextColor="navy"
                    cursor="pointer"
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Not Pot Bail Fund.
                  </Text>
                </Link>
              </Text>
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  )
}

export default ProductDescription
